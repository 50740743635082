(function () {
    "use strict";

    angular.module('informaApp')
        .directive('infButton', Button);

    function Button() {
        return {
            restrict: 'E',
            templateUrl: 'directives/home/inf-button/template.ptl.html',
            scope: {
                onClick: '=?',
                label: '@',
                text: '@',
                tooltip: '@',
                loading: '='
            },
            link: function (scope, element, attr) {
                scope.click = function () {
                    if (scope.onClick && !scope.loading){
                        scope.onClick();
                    }
                }
            }
        }
    }
})();
